import { graphql, useStaticQuery } from 'gatsby';
import { IUserObligationsResponse } from 'interfaces/interfaces';

const useUserObligations = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { title, obligations },
      },
    },
  } = useStaticQuery<IUserObligationsResponse>(graphql`
    query userObligations {
      markdownRemark(frontmatter: { type: { eq: "userObligations" } }) {
        frontmatter {
          english {
            title
            obligations {
              id
              iconNumber
              title
              description
              height
              link
            }
          }
        }
      }
    }
  `);

  return { title, obligations };
};

export default useUserObligations;
