import useUserObligations from './useUserObligations';
import Section from 'components/section/components/Section';
import CardInfo from 'components/card-info/components/CardInfo';
import { numbersType, WORKS } from '../../constants/works';
import { useTranslation } from 'react-i18next';

const styles = {
  justifyContent: 'flex-start',
};
const MARGIN_RIGHT = 30;

const UserObligations = () => {
  const { title, obligations } = useUserObligations();
  const { t } = useTranslation();

  return (
    <Section title={t(title)} styles={styles}>
      {obligations?.map(
        ({ id, title, iconNumber, description, height, link }) => {
          return (
            <CardInfo
              key={id}
              title={t(title)}
              description={t(description)}
              icon={WORKS.blackNumbers[iconNumber as numbersType]}
              height={height}
              withOpen={false}
              isLink={!!link}
              link={link}
              marginRight={MARGIN_RIGHT}
            />
          );
        },
      )}
    </Section>
  );
};

export default UserObligations;
