import { graphql, useStaticQuery } from 'gatsby';
import { IAboutResponse } from 'interfaces/interfaces';

const useSeamlessExperience = () => {
  const {
    markdownRemark: {
      frontmatter: {
        english: { title, description },
      },
    },
  } = useStaticQuery<IAboutResponse>(graphql`
    query seamlessExperience {
      markdownRemark(frontmatter: { type: { eq: "seamlessExperience" } }) {
        frontmatter {
          english {
            title
            description
          }
        }
      }
    }
  `);

  return { title, description };
};

export default useSeamlessExperience;
