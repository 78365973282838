import Section from 'components/section/components/Section';
import useSeamlessExperience from './useSeamlessExperience';
import { SectionDescription } from 'components/section/styles/styled';
import { useTranslation } from 'react-i18next';

const SeamlessExperience = () => {
  const { title, description } = useSeamlessExperience();
  const { t } = useTranslation();

  return (
    <Section title={t(title)}>
      <SectionDescription>{t(description)}</SectionDescription>
    </Section>
  );
};

export default SeamlessExperience;
